/*
| Developed by Starton
| Filename : NavUser.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import React from "react";
import {
  Avatar,
  Button,
  Divider,
  Stack,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import { LogOut, MoreVertical } from "lucide-react";
import { useGlobalContext, GlobalActionType } from "@starton/design-system";
import { useSessionMe } from "@/modules/auth/api/session.hooks";
import { useAuthContext } from "@/modules/auth/provider/AuthProvider";
import { UserRoleMapping } from "@/modules/user/api/user.dtos";
import { SessionEndpoint } from "@/modules/auth/api/session.endpoint";

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const NavUser = () => {
  const { data: session, mutate } = useSessionMe();
  const { openAuthDialog } = useAuthContext();
  const { state, dispatch } = useGlobalContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Get avatar letters
  // ---------------------------------------------------------------------------
  const getAvatarLetters = (name: string | undefined) => {
    if (!name) return "";
    const words = name.split(" ");
    return words
      .slice(0, 3)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  // Handle menu
  // ---------------------------------------------------------------------------
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle logout
  // ---------------------------------------------------------------------------
  const handleLogout = async () => {
    try {
      await SessionEndpoint.destroy().fetcher();
      await mutate(undefined);
      handleMenuClose();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  // Handle theme toggle
  // ---------------------------------------------------------------------------
  const handleThemeToggle = () => {
    dispatch({
      type: GlobalActionType.SET_PALETTE,
      payload: {
        mode: state.palette?.mode === "dark" ? "light" : "dark",
      },
    });
  };

  // Render
  // ---------------------------------------------------------------------------
  if (!session) {
    return (
      <React.Fragment>
        <Divider sx={{ mt: "auto" }} />
        <Stack spacing={1} sx={{ p: 2 }}>
          <Button
            onClick={() => openAuthDialog("login")}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Login
          </Button>
          <Button
            onClick={() => openAuthDialog("register")}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Create account
          </Button>
        </Stack>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Divider sx={{ mt: "auto" }} />
      <Stack direction="row" spacing={2} sx={{ p: 2, alignItems: "center" }}>
        <Avatar>{getAvatarLetters(session?.name)}</Avatar>
        <Stack>
          <Typography variant="body2">
            {session?.name || "Anonymous"}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {session?.role
              ? UserRoleMapping[session.role].label
              : "No role selected"}
          </Typography>
        </Stack>
        <Tooltip title="Menu">
          <IconButton onClick={handleMenuOpen} sx={{ ml: "auto" }}>
            <MoreVertical size={20} />
          </IconButton>
        </Tooltip>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Dark mode</Typography>
            <Switch
              checked={state.palette?.mode === "dark"}
              onChange={handleThemeToggle}
              size="small"
            />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <LogOut size={20} />
            <Typography>Logout</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
